import { Component, Input, Output, SimpleChanges, EventEmitter, OnChanges } from '@angular/core';
import { IArticleFilters, IFilterOption, IArticleFilterChangeEvent } from '../../shared.definitions';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-filter-collection',
  templateUrl: './filter-collection.component.html',
  styleUrls: ['./filter-collection.component.scss'],
})
export class FilterCollectionComponent implements OnChanges {
  @Input() filters: IArticleFilters;
  @Output() filterChange = new EventEmitter<IArticleFilterChangeEvent>();
  public activeFilters: IArticleFilters;
  filterOpened = {
    destinations: false,
    thematics: false,
  };
  searchControl: UntypedFormControl;
  ThematicFirst = [];
  thematicSecond = [];
  thematicThird = [];

  ngOnChanges(change: SimpleChanges): void {
    if (change.filters) {
      if (change.filters.firstChange) {
        this.searchControl = new UntypedFormControl(this.filters.search);
      }
      this.activeFilters = JSON.parse(JSON.stringify(change.filters.currentValue));
      this.initthematicColumbs();
      this.searchControl.setValue(change.filters.currentValue.search);
    }
  }

  initthematicColumbs(): void {
    this.ThematicFirst = [];
    this.thematicSecond = [];
    this.thematicThird = [];
    let activeFiltersThematicsRowCount = 0;
    const thematicIndex = this.activeFilters.thematics.length - 1;

    if (this.activeFilters.thematics && this.activeFilters.thematics.length > 0) {
      activeFiltersThematicsRowCount = Math.ceil(this.activeFilters.thematics.length / 3);
    } else {
      activeFiltersThematicsRowCount = 0;
    }
    for (let i = 0; i < activeFiltersThematicsRowCount; i++) {
      if (thematicIndex >= 0 + i * 3) {
        this.ThematicFirst.push(this.activeFilters.thematics[0 + i * 3]);
      }
      if (thematicIndex >= 1 + i * 3) {
        this.thematicSecond.push(this.activeFilters.thematics[1 + i * 3]);
      }
      if (thematicIndex >= 2 + i * 3) {
        this.thematicThird.push(this.activeFilters.thematics[2 + i * 3]);
      }
    }
  }

  toggleFilter(filter: string): void {
    this.filterOpened[filter] = !this.filterOpened[filter];
    if (!this.filterOpened[filter]) {
      this.activeFilters[filter] = JSON.parse(JSON.stringify(this.filters[filter]));
    }
    Object.keys(this.filterOpened).forEach((key) => {
      if (key !== filter) {
        this.filterOpened[key] = false;
      }
    });
  }

  closeAllFilter(): void {
    this.filterOpened = {
      destinations: false,
      thematics: false,
    };
  }

  onOptionClick(option: IFilterOption): void {
    option.checked = !option.checked;
    const change = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterChange.emit(change);
  }

  onFilterClear(filter: string): void {
    this.activeFilters[filter].forEach((f) => {
      f.checked = false;
    });
    const change = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterChange.emit(change);
  }

  onSearch(): void {
    this.activeFilters.search = this.searchControl.value;
    const change = JSON.parse(JSON.stringify(this.activeFilters));
    change.scroll = true;
    this.filterChange.emit(change);
  }
}
