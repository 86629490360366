<header class="desktop" [ngClass]="{ home: isHomePage, sticky: isSticky }">
  <div class="head-top">
    <div class="wrapper">
      <div class="left">
        <a [routerLink]="['/']" class="head-link default bold main">{{ 'HEADER.the-official-travel-guide' | translate }}</a>
      </div>
      <div class="right">
        <!-- <app-weather-widget [routerLink]="['/', 'ROUTES.weather' | translate]"></app-weather-widget> -->
        <app-language-switcher></app-language-switcher>
        <!-- TODO: remove ngIf="!isProduction" if it goes production -->
        <a (click)="onClickToggleSearchBar()" class="head-search" *ngIf="!isProduction">
          <i class="icon icon-search"></i>
          {{ 'SEARCH.search' | translate }}
        </a>
      </div>
    </div>
  </div>
  <div #headMain class="head-main" [ngClass]="{ sticky: isSticky }">
    <ng-container *ngIf="isHomePage">
      <div class="custom-gradient" [ngClass]="{ sticky: isSticky }" *ngIf="gradient" aria-hidden="true"></div>

      <span
        *ngIf="imageUrl && !youtubeUrl"
        [ngStyle]="{ opacity: headOpacity, 'background-image': 'url(' + imageUrl + ')' | ssrEmptyString }"
        [attr.aria-label]="imageAlt"
        aria-hidden="true"
        role="img"
        class="bgImage"
        corporateImageLazyLoad
      ></span>

      <app-video-player *ngIf="youtubeUrl" [url]="youtubeUrl" aria-hidden="true"></app-video-player>

      <div class="hero-content" [ngClass]="{ 'no-events': headOpacity === 0 }">
        <div [ngStyle]="{ opacity: headOpacity }" class="bgColor" aria-hidden="true"></div>

        <div [ngStyle]="{ opacity: headOpacity }" class="group group275" aria-hidden="true"></div>
        <div [ngStyle]="{ opacity: headOpacity }" class="group group276" aria-hidden="true"></div>
        <div [ngStyle]="{ opacity: headOpacity }" class="group group156" aria-hidden="true"></div>
        <div [ngStyle]="{ opacity: headOpacity }" class="specicon {{ thematicIconKey }}" aria-hidden="true"></div>
        <h1 *ngIf="title" class="home-title">{{ title }}</h1>
        <div *ngIf="!isSticky" class="home-location">
          <div class="wrapper">
            <div class="map-titles">
              <span>{{ mapIconSubtitle0 }}</span>
              <span>{{ mapIconSubtitle1 }}</span>
              <span>{{ mapIconSubtitle2 }}</span>
            </div>
            <i class="icon {{ mapIconKey }}"></i>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="main-nav" #mainNav>
      <a class="brand-logo" [routerLink]="['/'] | localize" [attr.aria-label]="'404.back-to-main-page' | translate"
        ><i class="icon icon-logo-white"></i
      ></a>
      <button class="button button-spec purple" [appBackgroundColor]="'purple'" (click)="toggleNavContent(1)">
        {{ 'label.what-to-do' | translate }}
        <i class="icon icon-arrow-right-button-white" [ngClass]="{ opened: actualNavId === 1 }"></i>
      </button>
      <div class="nav-content-wrapper" [ngClass]="{ opened: actualNavId === 1 }">
        <div class="nav-content purple" [appBackgroundColor]="'purple'" *ngIf="menuWhatToDo">
          <div class="row">
            <div class="col-lg-3">
              <ul *ngFor="let item of menuWhatToDo.tree.left">
                <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item, visible: actualNavId === 1 }">
                </ng-container>
              </ul>
            </div>
            <div class="col-lg-3">
              <ul *ngFor="let item of menuWhatToDo.tree.right">
                <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item, visible: actualNavId === 1 }">
                </ng-container>
              </ul>
            </div>
            <div class="col-lg-6">
              <div class="image-link-group half">
                <ng-container
                  *ngFor="let recommendation of menuWhatToDo.recommendationsLeft"
                  [ngTemplateOutlet]="menuRecommendationItem"
                  [ngTemplateOutletContext]="{ recommendation: recommendation, visible: actualNavId === 1 }"
                >
                </ng-container>
                <ng-container
                  *ngFor="let recommendation of menuWhatToDo.recommendationsRight"
                  [ngTemplateOutlet]="menuRecommendationItem"
                  [ngTemplateOutletContext]="{ recommendation: recommendation, visible: actualNavId === 1 }"
                >
                </ng-container>
              </div>
            </div>
            <div class="col-12 bottom-wrapper">
              <button [tabindex]="actualNavId === 1 ? 0 : -1" class="close-button" (click)="closeNavContent()">
                {{ 'label.close' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button class="button button-spec red" [appBackgroundColor]="'red'" (click)="toggleNavContent(2)">
        {{ 'label.where-to-go' | translate }}
        <i class="icon icon-arrow-right-button-white" [ngClass]="{ opened: actualNavId === 2 }"></i>
      </button>
      <div class="nav-content-wrapper" [ngClass]="{ opened: actualNavId === 2 }">
        <div class="nav-content red" [appBackgroundColor]="'red'" *ngIf="menuWhereToGo">
          <div class="row">
            <div class="col-lg-3">
              <ul *ngFor="let item of menuWhereToGo.tree.left">
                <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item, visible: actualNavId === 2 }">
                </ng-container>
              </ul>
            </div>
            <div class="col-lg-3">
              <ul *ngFor="let item of menuWhereToGo.tree.right">
                <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item, visible: actualNavId === 2 }">
                </ng-container>
              </ul>
            </div>
            <div class="col-lg-6">
              <div class="image-link-group half">
                <ng-container
                  *ngFor="let recommendation of menuWhereToGo.recommendationsLeft"
                  [ngTemplateOutlet]="menuRecommendationItem"
                  [ngTemplateOutletContext]="{ recommendation: recommendation, visible: actualNavId === 2 }"
                >
                </ng-container>
                <ng-container
                  *ngFor="let recommendation of menuWhereToGo.recommendationsRight"
                  [ngTemplateOutlet]="menuRecommendationItem"
                  [ngTemplateOutletContext]="{ recommendation: recommendation, visible: actualNavId === 2 }"
                >
                </ng-container>
              </div>
            </div>
            <div class="col-12 bottom-wrapper">
              <button [tabindex]="actualNavId === 2 ? 0 : -1" class="close-button" (click)="closeNavContent()">
                {{ 'label.close' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button class="button button-spec blue-light" [appBackgroundColor]="'blue-light'" (click)="toggleNavContent(3)">
        {{ 'label.plan-your-trip' | translate }}
        <i class="icon icon-arrow-right-button-white" [ngClass]="{ opened: actualNavId === 3 }"></i>
      </button>
      <!-- Header Hungary For -->
      <div class="nav-content-wrapper" [ngClass]="{ opened: actualNavId === 3 }">
        <div class="nav-content blue-light" [appBackgroundColor]="'blue-light'" *ngIf="menuHungaryFor">
          <div class="row">
            <div class="col-lg-3">
              <ul *ngFor="let item of menuHungaryFor.tree.left">
                <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item, visible: actualNavId === 3 }">
                </ng-container>
              </ul>
            </div>
            <div class="col-lg-3">
              <ul *ngFor="let item of menuHungaryFor.tree.right">
                <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item, visible: actualNavId === 3 }">
                </ng-container>
              </ul>
            </div>
            <div class="col-lg-6">
              <div class="image-link-group half">
                <ng-container
                  *ngFor="let recommendation of menuHungaryFor.recommendationsLeft"
                  [ngTemplateOutlet]="menuRecommendationItem"
                  [ngTemplateOutletContext]="{ recommendation: recommendation, visible: actualNavId === 3 }"
                >
                </ng-container>
                <ng-container
                  *ngFor="let recommendation of menuHungaryFor.recommendationsRight"
                  [ngTemplateOutlet]="menuRecommendationItem"
                  [ngTemplateOutletContext]="{ recommendation: recommendation, visible: actualNavId === 3 }"
                >
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-12 bottom-wrapper">
            <button [tabindex]="actualNavId === 3 ? 0 : -1" class="close-button" (click)="closeNavContent()">
              {{ 'label.close' | translate }}
            </button>
          </div>
        </div>
      </div>
      <button class="button button-spec green" [appBackgroundColor]="'green'" (click)="toggleNavContent(4)">
        {{ 'label.places-to-go' | translate }}
        <i class="icon icon-arrow-right-button-white" [ngClass]="{ opened: actualNavId === 4 }"></i>
      </button>
      <!-- Header Plan Your Trip -->
      <div class="nav-content-wrapper" [ngClass]="{ opened: actualNavId === 4 }">
        <div class="nav-content green" [appBackgroundColor]="'green'" *ngIf="menuPlanYourTrip">
          <div class="row">
            <div class="col-lg-3">
              <ul *ngFor="let item of menuPlanYourTrip.tree.left">
                <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item, visible: actualNavId === 4 }">
                </ng-container>
              </ul>
            </div>
            <div class="col-lg-3">
              <ul *ngFor="let item of menuPlanYourTrip.tree.right">
                <ng-container [ngTemplateOutlet]="menuTreeItem" [ngTemplateOutletContext]="{ item: item, visible: actualNavId === 4 }">
                </ng-container>
              </ul>
            </div>
            <div class="col-lg-6">
              <div class="image-link-group half">
                <ng-container
                  *ngFor="let recommendation of menuPlanYourTrip.recommendationsLeft"
                  [ngTemplateOutlet]="menuRecommendationItem"
                  [ngTemplateOutletContext]="{ recommendation: recommendation, visible: actualNavId === 4 }"
                >
                </ng-container>
                <ng-container
                  *ngFor="let recommendation of menuPlanYourTrip.recommendationsRight"
                  [ngTemplateOutlet]="menuRecommendationItem"
                  [ngTemplateOutletContext]="{ recommendation: recommendation, visible: actualNavId === 4 }"
                >
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-12 bottom-wrapper">
            <button [tabindex]="actualNavId === 4 ? 0 : -1" class="close-button" (click)="closeNavContent()">
              {{ 'label.close' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- TODO: remove ngIf="!isProduction" if it goes production -->
    <div class="header-search" [class.isOpen]="isSearchDropdownOpened" *ngIf="!isProduction">
      <app-search-form [isForNavigation]="true"></app-search-form>
    </div>
  </div>
</header>

<header class="mobile" [ngClass]="{ sticky: isStickyMobile }">
  <div class="fixed-spacer"></div>
  <div class="wrapper">
    <div class="head-top">
      <div class="left" (click)="closeMobileMenu()">
        <a [routerLink]="['/'] | localize" class="brand-logo" [attr.aria-label]="'404.back-to-main-page' | translate"
          ><i class="icon icon-logo-white"></i
        ></a>
      </div>
      <div class="right">
        <!-- TODO: remove ngIf="!isProduction" if it goes production -->
        <a
          [routerLink]="['/', 'search'] | localize"
          class="head-search"
          *ngIf="!isProduction"
          [attr.aria-label]="'SEARCH.search' | translate"
        >
          <i class="icon icon-search"></i>
        </a>

        <button
          (click)="toggleMobileMenu()"
          [attr.aria-pressed]="isMobileMenuOpen$ | async"
          [attr.aria-label]="'label.toggle-menu-button' | translate"
        >
          <i class="icon icon-burger-white" *ngIf="(isMobileMenuOpen$ | async) === false"></i>
          <i class="icon icon-close-white" *ngIf="isMobileMenuOpen$ | async"></i>
        </button>
      </div>
    </div>

    <div class="mobile-nav-content" [ngClass]="{ opened: isMobileMenuOpen$ | async }">
      <div class="nav-main-page">
        <div class="top">
          <div class="main-links">
            <button class="main-link purple" [appBackgroundColor]="'purple'" (click)="toggleNavContent(1)">
              {{ 'label.what-to-do' | translate }}
              <i class="icon icon-arrow-right-button-white"></i>
            </button>
            <button class="main-link red" [appBackgroundColor]="'red'" (click)="toggleNavContent(2)">
              {{ 'label.where-to-go' | translate }}
              <i class="icon icon-arrow-right-button-white"></i>
            </button>
            <button class="main-link green" [appBackgroundColor]="'green'" (click)="toggleNavContent(4)">
              {{ 'label.places-to-go' | translate }}
              <i class="icon icon-arrow-right-button-white"></i>
            </button>
            <button class="main-link blue-light" [appBackgroundColor]="'blue-light'" (click)="toggleNavContent(3)">
              {{ 'label.plan-your-trip' | translate }}
              <i class="icon icon-arrow-right-button-white"></i>
            </button>
          </div>
        </div>
        <div class="bottom">
          <div class="bottom-link-list"></div>
          <div class="bottom-socials">
            <div class="left">
              <a
                class="link"
                *ngIf="footerData?.youtubeUrl"
                [href]="footerData?.youtubeUrl | safe : 'url'"
                target="_blank"
                aria-label="Youtube"
                ><i class="icon icon-youtube-blue"></i
              ></a>
              <a
                class="link"
                *ngIf="footerData?.facebookUrl"
                [href]="footerData?.facebookUrl | safe : 'url'"
                target="_blank"
                aria-label="Facebook"
                ><i class="icon icon-facebook-blue"></i
              ></a>
              <a
                class="link"
                *ngIf="footerData?.twitterUrl"
                [href]="footerData?.twitterUrl | safe : 'url'"
                target="_blank"
                aria-label="Twitter"
                ><i class="icon icon-twitter-blue"></i
              ></a>
              <a
                class="link"
                *ngIf="footerData?.instagramUrl"
                [href]="footerData?.instagramUrl | safe : 'url'"
                target="_blank"
                aria-label="Instagram"
                ><i class="icon icon-instagram-blue"></i
              ></a>
            </div>
            <div class="right"></div>
          </div>
          <!-- <app-weather-widget [routerLink]="['/', 'weather']"></app-weather-widget> -->
          <app-language-switcher></app-language-switcher>
        </div>

        <!-- IDE -->
      </div>

      <div class="nav-subpage purple" [appBackgroundColor]="'purple'" [ngClass]="{ opened: actualNavId === 1 }">
        <button class="back-button" (click)="closeNavContent()">
          <i class="icon icon-arrow-left-white"></i>{{ 'label.back' | translate }}
        </button>
        <ul class="link-list" *ngIf="menuWhatToDo">
          <li *ngFor="let item of menuWhatToDo.tree.left">
            <ng-container [ngTemplateOutlet]="menuTreeLinkMobil" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </li>
          <li *ngFor="let item of menuWhatToDo.tree.right">
            <ng-container [ngTemplateOutlet]="menuTreeLinkMobil" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </li>
        </ul>
      </div>
      <div class="nav-subpage red" [appBackgroundColor]="'red'" [ngClass]="{ opened: actualNavId === 2 }">
        <button class="back-button" (click)="closeNavContent()">
          <i class="icon icon-arrow-left-white"></i>{{ 'label.back' | translate }}
        </button>
        <ul class="link-list" *ngIf="menuWhereToGo">
          <li *ngFor="let item of menuWhereToGo.tree.left">
            <ng-container [ngTemplateOutlet]="menuTreeLinkMobil" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </li>
          <li *ngFor="let item of menuWhereToGo.tree.right">
            <ng-container [ngTemplateOutlet]="menuTreeLinkMobil" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </li>
        </ul>
      </div>
      <div class="nav-subpage blue-light" [appBackgroundColor]="'blue-light'" [ngClass]="{ opened: actualNavId === 3 }">
        <button class="back-button" (click)="closeNavContent()">
          <i class="icon icon-arrow-left-white"></i>{{ 'label.back' | translate }}
        </button>

        <ul class="link-list" *ngIf="menuHungaryFor">
          <li *ngFor="let item of menuHungaryFor.tree.left">
            <ng-container [ngTemplateOutlet]="menuTreeLinkMobil" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </li>
          <li *ngFor="let item of menuHungaryFor.tree.right">
            <ng-container [ngTemplateOutlet]="menuTreeLinkMobil" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </li>
        </ul>
      </div>
      <div class="nav-subpage green" [appBackgroundColor]="'green'" [ngClass]="{ opened: actualNavId === 4 }">
        <button class="back-button" (click)="closeNavContent()">
          <i class="icon icon-arrow-left-white"></i>{{ 'label.back' | translate }}
        </button>
        <ul class="link-list" *ngIf="menuPlanYourTrip">
          <li *ngFor="let item of menuPlanYourTrip.tree.left">
            <ng-container [ngTemplateOutlet]="menuTreeLinkMobil" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </li>
          <li *ngFor="let item of menuPlanYourTrip.tree.right">
            <ng-container [ngTemplateOutlet]="menuTreeLinkMobil" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    class="mobile-head-image"
    [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' } | ssrEmptyString"
    *ngIf="isHomePage"
    [attr.aria-label]="imageAlt"
    aria-hidden="true"
    corporateImageLazyLoad
  >
    <div class="custom-gradient" *ngIf="gradient" aria-hidden="true"></div>
    <div class="home-title" *ngIf="title">{{ title }}</div>
    <div *ngIf="thematicIconKey" class="specicon mobile {{ thematicIconKey }}" aria-hidden="true"></div>
  </div>
</header>

<ng-template #menuTreeItem let-item="item" let-visible="visible">
  <li>
    <a
      [tabindex]="visible ? 0 : -1"
      *ngIf="item?.customUrl"
      [href]="item.customUrl"
      [target]="item.targetBlank ? '_blank' : '_self'"
      class="big"
      >{{ item.label }}</a
    >
    <a [tabindex]="visible ? 0 : -1" *ngIf="item?.routerLink" [routerLink]="item.routerLink" class="big">{{ item.label }}</a>
    <a [tabindex]="visible ? 0 : -1" *ngIf="!item.customUrl && !item.routerLink" class="big">{{ item.label }}</a>
  </li>
  <li *ngFor="let child of item.children">
    <a [tabindex]="visible ? 0 : -1" *ngIf="child?.customUrl" [href]="child.customUrl" [target]="child.targetBlank ? '_blank' : '_self'">{{
      child.label
    }}</a>
    <a [tabindex]="visible ? 0 : -1" *ngIf="child?.routerLink" [routerLink]="child.routerLink">{{ child.label }}</a>
  </li>
</ng-template>

<ng-template #menuRecommendationItem let-recommendation="recommendation" let-visible="visible">
  <a
    [tabindex]="visible ? 0 : -1"
    *ngIf="recommendation?.customUrl"
    class="image-link"
    [href]="recommendation.customUrl"
    [target]="recommendation.targetBlank ? '_blank' : '_self'"
  >
    <div class="image" [ngStyle]="{ 'background-image': recommendation.templateUrl } | ssrEmptyString" corporateImageLazyLoad></div>
    <div class="title">{{ recommendation.label }}</div>
  </a>
  <a [tabindex]="visible ? 0 : -1" *ngIf="recommendation?.routerLink" class="image-link" [routerLink]="recommendation.routerLink">
    <div class="image" [ngStyle]="{ 'background-image': recommendation.templateUrl } | ssrEmptyString" corporateImageLazyLoad></div>
    <div class="title">{{ recommendation.label }}</div>
  </a>
</ng-template>

<ng-template #menuTreeLinkMobil let-item="item">
  <a *ngIf="item?.customUrl" (click)="routeCustomUrl(item.customUrl)">{{ item.label }}</a>
  <a *ngIf="item?.routerLink" (click)="closeMobileMenu()" [routerLink]="item.routerLink">{{ item.label }}</a>
  <a *ngIf="!item?.customUrl && !item?.routerLink" (click)="closeMobileMenu()" [routerLink]="item.routerLink">{{ item.label }}</a>
</ng-template>
