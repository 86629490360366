import { Component, Input, Output, SimpleChanges, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { IFilterOption, IEventFilters } from '../../shared.definitions';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateLabelService } from '../../services/date-label.service';
import { MatCalendar } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';

export const MY_FORMATS = {
  parse: { dateInput: 'DD/MMMM/YYYY' },
  display: {
    dateInput: 'DD/MMMM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MMMM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-event-collection-filter',
  templateUrl: './event-collection-filter.component.html',
  styleUrls: ['./event-collection-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EventCollectionFilterComponent implements OnChanges {
  @ViewChild('calendar', { static: true }) calendar: MatCalendar<Moment>;
  @ViewChild('calendar2', { static: true }) calendar2: MatCalendar<Moment>;
  @Input() filters: IEventFilters;
  @Output() filterChange = new EventEmitter<IEventFilters>();

  activeFilters: IEventFilters;
  fromDate: Date;
  toDate: Date;
  toIsGreater = true;
  dateRange: [];
  dateFormat = 'yyyy/MM/dd';
  monthFormat = 'yyyy/MM';
  form: UntypedFormGroup = new UntypedFormGroup({});
  date: Date;
  filterOpened = {
    destinations: false,
    dateRange: false,
    thematics: false,
  };
  searchControl: UntypedFormControl;

  constructor(
    private readonly dateLabelService: DateLabelService,
    private readonly translate: TranslateService,
    private readonly adapter: DateAdapter<any>
  ) {
    this.adapter.setLocale(this.translate.currentLang);
  }

  onFromSelect(event): void {
    this.fromDate = event;
    this.compareDate();
    this.bothDatesSet();
  }

  onToSelect(event): void {
    this.toDate = event;
    this.compareDate();
    this.bothDatesSet();
  }

  bothDatesSet(): void {
    if (this.fromDate && this.toDate && this.toIsGreater === true) {
      this.activeFilters.dateRange = [this.fromDate, this.toDate];
      const change = JSON.parse(JSON.stringify(this.activeFilters));
      this.filterChange.emit(change);
    }
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change?.filters) {
      if (change.filters.firstChange) {
        this.searchControl = new UntypedFormControl(change.filters.currentValue.search);
      }
      this.activeFilters = change.filters.currentValue;
      this.searchControl.setValue(change.filters.currentValue.search);
      if (change?.filters?.currentValue?.dateRange?.length) {
        this.fromDate = change.filters.currentValue.dateRange[0];
        this.toDate = change.filters.currentValue.dateRange[1];
      } else {
        this.fromDate = undefined;
        this.toDate = undefined;
        this.activeFilters.dateRange = [];
        this.compareDate();
      }
    }
  }

  toggleFilter(filter: string): void {
    this.filterOpened[filter] = !this.filterOpened[filter];
    if (!this.filterOpened[filter]) {
      if (filter === 'dateRange') {
        this.fromDate = this.filters?.dateRange?.length ? this.filters.dateRange[0] : undefined;
        this.toDate = this.filters?.dateRange?.length ? this.filters.dateRange[1] : undefined;
      } else {
        this.activeFilters[filter] = JSON.parse(JSON.stringify(this.filters[filter]));
      }
    }

    Object.keys(this.filterOpened).forEach((key) => {
      if (key !== filter) {
        this.filterOpened[key] = false;
      }
    });
  }

  closeAllFilter(): void {
    this.filterOpened = {
      destinations: false,
      dateRange: false,
      thematics: false,
    };
  }

  onOptionClick(option: IFilterOption): void {
    option.checked = !option.checked;
    const change = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterChange.emit(change);
  }

  onFilterClear(filter: string): void {
    this.activeFilters[filter].forEach((f) => {
      f.checked = false;
    });
    const change = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterChange.emit(change);
  }

  onSearch(): void {
    this.activeFilters.search = this.searchControl.value;
    const change = JSON.parse(JSON.stringify(this.activeFilters));
    change.scroll = true;
    this.filterChange.emit(change);
  }

  onDateFilterClear(): void {
    this.fromDate = undefined;
    this.toDate = undefined;
    this.activeFilters.dateRange = [];
    this.compareDate();
    const change = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterChange.emit(change);
  }

  dateLabelSingle(date1: string | Date): string {
    return this.dateLabelService.createLabelSingle(date1);
  }

  compareDate(): void {
    if (this.fromDate && this.toDate) {
      const d1 = new Date(this.fromDate);
      const d2 = new Date(this.toDate);

      const same = d1.getTime() === d2.getTime();
      if (same) {
        this.toIsGreater = true;
      }
      this.toIsGreater = d1 < d2;
    } else {
      this.toIsGreater = true;
    }
  }

  toggleArchive(): void {
    this.activeFilters.isArchive = !this.activeFilters.isArchive;
    this.filterChange.emit(this.activeFilters);
  }
}
