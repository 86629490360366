import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { ApiService } from '../../services/api.service';
import { ICampaignServiceCategory, ICampaignServiceType } from '../../shared.definitions';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormService } from '../../services/form.service';

@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.scss'],
})
export class CampaignFormComponent extends BaseComponent implements OnInit {
  dataManagementDoc: string;
  dataManagementDocText: string;
  policyDoc: string;
  policyDocText: string;
  text: string;
  serviceTypes: Array<ICampaignServiceType>;
  serviceCategories: Array<ICampaignServiceCategory> = [];
  formSent = false;
  campaignForm: UntypedFormGroup;
  pageUuid: string;
  taxNumberMask: (string | RegExp)[];

  constructor(private readonly apiService: ApiService, private readonly route: ActivatedRoute, private readonly formService: FormService) {
    super();
    this.taxNumberMask = this.formService.taxNumberMask;
  }

  ngOnInit(): void {
    this.pageUuid = this.route.snapshot.data.data.uuid;

    if (this.componentObject) {
      this.text = this.componentObject.text;
      this.dataManagementDocText = this.componentObject.dataManagementDocText;
      this.policyDocText = this.componentObject.policyDocText;
      this.serviceTypes = this.componentObject.serviceTypes;

      this.setServiceCategories();

      if (this.componentObject.dataManagementDoc) {
        this.apiService.getFile$(this.componentObject.dataManagementDoc.uuid).subscribe((res) => (this.dataManagementDoc = res.path));
      }

      if (this.componentObject.policyDoc) {
        this.apiService.getFile$(this.componentObject.policyDoc.uuid).subscribe((res) => (this.policyDoc = res.path));
      }
    }

    this.initFormGroup();
  }

  onCampaignFormSubmit(): void {
    if (this.campaignForm.valid) {
      this.apiService.getCampaignForm$().subscribe((res) => {
        res.data = res.data.map((field) => {
          if (field.key === 'serviceTypes' && this.serviceTypes) {
            field.value = this.serviceTypes.filter((type) => this.campaignForm.value[type.title]);
          } else {
            field.value = this.campaignForm.value[field.key];
          }
          return field;
        });

        this.apiService
          .postCampaignForm$(this.pageUuid, res)
          /*eslint-disable rxjs/no-nested-subscribe*/
          .subscribe(() => (this.formSent = true));
      });
    }
  }

  initFormGroup(): void {
    const TAX_REGEXP = /^\d{8}-\d-\d{2}$/;
    const URL_REGEXP = '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const ZIP_REGEX = /^([a-zA-Z0-9 .:\\/_-]+)$/;

    this.campaignForm = new UntypedFormGroup({
      partnerName: new UntypedFormControl('', [Validators.required]),
      headAddressCity: new UntypedFormControl('', [Validators.required]),
      headAddressZipcode: new UntypedFormControl('', [Validators.required, Validators.pattern(ZIP_REGEX)]),
      headAddressStreet: new UntypedFormControl('', [Validators.required]),
      mailingAddressCity: new UntypedFormControl(''),
      mailingAddressZipcode: new UntypedFormControl('', [Validators.pattern(ZIP_REGEX)]),
      mailingAddressStreet: new UntypedFormControl(''),
      taxNumber: new UntypedFormControl('', [Validators.required, Validators.pattern(TAX_REGEXP)]),
      companyRegistrationNumber: new UntypedFormControl(''),
      representativeName: new UntypedFormControl('', [Validators.required]),
      representativeTitle: new UntypedFormControl('', [Validators.required]),
      contactName: new UntypedFormControl('', [Validators.required]),
      contactEmail: new UntypedFormControl('', [Validators.email, Validators.required]),
      contactCountryCode: new UntypedFormControl('+', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      contactPhoneNumber: new UntypedFormControl('', [Validators.required]),
      providerName: new UntypedFormControl('', [Validators.required]),
      url: new UntypedFormControl('', [Validators.required, Validators.pattern(URL_REGEXP)]),
      dataManagementAccept: new UntypedFormControl('', [Validators.requiredTrue]),
      policyAccept: new UntypedFormControl('', [Validators.requiredTrue]),
    });

    if (this.serviceTypes) {
      for (const type of this.serviceTypes) {
        this.campaignForm.addControl(type.title, new UntypedFormControl(''));
      }
    }
  }

  updateCountryCode(): void {
    if (this.campaignForm.controls['contactCountryCode'].value.charAt(0) !== '+') {
      this.campaignForm.controls['contactCountryCode'].patchValue('+' + this.campaignForm.controls['contactCountryCode'].value);
    }
  }

  setServiceCategories(): void {
    if (this.componentObject) {
      for (const type of this.componentObject.serviceTypes) {
        const obj = { categoryUuid: type.categoryUuid, categoryTitle: type.categoryTitle };
        if (this.serviceCategories && this.serviceCategories.length) {
          if (!this.serviceCategories.find((a) => a.categoryUuid === type.categoryUuid)) {
            this.serviceCategories.push(obj);
          }
        } else {
          this.serviceCategories.push(obj);
        }
      }
    }
  }
}
