import { ConsentConfig } from '@trendency/consent/lib/types/consent-config.types';

export const consentConfig: ConsentConfig = {
  current_lang: 'en',
  autoclear_cookies: true,
  page_scripts: true,
  cookie_name: 'playground-cookie-consent',
  force_consent: true,
  revision: 1,
  gui_options: {
    consent_modal: {
      layout: 'bar',
      position: 'bottom right',
      transition: 'slide',
      swap_buttons: false,
    },
    settings_modal: {
      layout: 'box',
      transition: 'slide',
    },
  },
  languages: {
    en: {
      consent_modal: {
        title: 'Cookie-kat használunk!',
        description:
        'Az oldal sütiket használ a honlap működtetése, használatának megkönnyítése érdekében. <button type="button" data-cc="c-settings" class="cc-link">Cookie beállítások</button>',
        primary_btn: {
          text: 'Elfogadom',
          role: 'accept_all',
        },
        secondary_btn: {
          text: 'Nem fogadom el',
          role: 'accept_necessary',
        },
      },
      settings_modal: {
        title: 'A használt cookie-k beállítása',
        save_settings_btn: 'Beállítások mentése',
        accept_all_btn: 'Mindet elfogadom',
        reject_all_btn: 'Mindet elutasítom',
        close_btn_label: 'Bezárás',
        cookie_table_headers: [
          { col1: 'Süti neve' },
          { col2: 'Domain' },
          { col3: 'Megőrzési idő' },
          { col4: 'Leírás' },
        ],
        blocks: [
          {
            title: 'Cookie-használat',
            description: `Az oldal Cookie-kat használ a megfelelő működés érdekében. További információt a <a href="/kozerdeku-adatok/suti-tajekoztato" target="_blank" class="cc-link">Cookie tájékoztatóban</a> talál.`,
          },
          {
            title:
            'Működést biztosító kötelező sütik (ez nem letiltható)',
            description: '',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true,
            },
            cookie_table: [
              // list of necessary cookies
              {
                col1: `_GRECAPTCHA`,
                col2: 'playground.com',
                col3: '179 nap',
                col4: `Emberek és robotok megkülönböztetésére szolgál`,
              },
            ],
          },
        ],
      },
    },
  },
}
