<app-header #header [footerData]="footerData" [menu]="menu" [languages]="languages"> </app-header>

<router-outlet></router-outlet>

<button (click)="scrollToTop()" class="to-top" [ngClass]="{ shown: toTopShown }" [attr.aria-label]="'label.to-top' | translate"></button>

<app-sz-2020-logo></app-sz-2020-logo>

<app-footer [footerData]="footerData" (openHeaderNav)="navContentChange()"></app-footer>

<!-- <app-popup [showDailyOnce]="true">
  <app-card-popup></app-card-popup>
</app-popup> -->

<!--<app-popup #popupLogin>-->
<!--    <app-popup-login></app-popup-login>-->
<!--</app-popup>-->

<!--<app-popup #popupDelete>-->
<!--  <div class="popup-title small">Delete</div>-->
<!--  <div class="popup-desc">Are you sure you wan to delete this article from your bucketlist?</div>-->

<!--  <div class="popup-delete-buttons">-->
<!--    <button class="button button-primary">no</button>-->
<!--    <button class="button button-secondary">yes</button>-->
<!--  </div>-->
<!--</app-popup>-->
