import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ITripFilters, IFilterOption, ITripFilterChangeEvent } from '../../shared.definitions';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-filter-itinerary',
  templateUrl: './filter-itinerary.component.html',
  styleUrls: ['./filter-itinerary.component.scss'],
})
export class FilterItineraryComponent implements OnChanges {
  @Input() filters: ITripFilters;
  @Output() filterChange = new EventEmitter<ITripFilterChangeEvent>();
  public activeFilters: ITripFilters;

  filterOpened = {
    destinations: false,
    targetAudiences: false,
    tripLengths: false,
  };
  searchControl: UntypedFormControl;

  ngOnChanges(change: SimpleChanges): void {
    if (change.filters) {
      if (change.filters.firstChange) {
        this.searchControl = new UntypedFormControl(this.filters.search);
      }
      this.activeFilters = JSON.parse(JSON.stringify(change.filters.currentValue));
      this.searchControl.setValue(change.filters.currentValue.search);
    }
  }

  toggleFilter(filter: string): void {
    this.filterOpened[filter] = !this.filterOpened[filter];
    if (!this.filterOpened[filter]) {
      this.activeFilters[filter] = JSON.parse(JSON.stringify(this.filters[filter]));
    }
    Object.keys(this.filterOpened).forEach((key) => {
      if (key !== filter) {
        this.filterOpened[key] = false;
      }
    });
  }

  closeAllFilter(): void {
    this.filterOpened = {
      destinations: false,
      targetAudiences: false,
      tripLengths: false,
    };
  }

  onOptionClick(option: IFilterOption): void {
    option.checked = !option.checked;
    const change = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterChange.emit(change);
  }

  onFilterClear(filter: string): void {
    this.activeFilters[filter].forEach((f) => {
      f.checked = false;
    });
    const change = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterChange.emit(change);
  }

  onSearch(): void {
    this.activeFilters.search = this.searchControl.value;
    const change = JSON.parse(JSON.stringify(this.activeFilters));
    change.scroll = true;
    this.filterChange.emit(change);
  }
}
