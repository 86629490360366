import { CommonModule } from '@angular/common';
import { ApplicationRef, NgModule } from '@angular/core';
import { ActivationEnd, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TrendencyUtilsModule, TrendencyUtilsService } from '../utils';
import { TrendFullscreenLoaderComponent } from './components/fullscreen-loader/fullscreen-loader.component';
import { TrendencyProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { TrendencyButtonLoadDirective } from './directives/button-load.directive';
import { TrendencyIframeLazyLoadDirective } from './directives/iframe-lazy-load.directive';
import { TrendencyFullscreenLoaderService } from './services/fullscreen-loader.service';
import { TrendencyProgressBarService } from './services/progress-bar.service';

@NgModule({
  imports: [CommonModule, TrendencyUtilsModule],
  declarations: [
    TrendencyProgressBarComponent,
    TrendFullscreenLoaderComponent,
    TrendencyButtonLoadDirective,
    TrendencyIframeLazyLoadDirective,
  ],
  providers: [TrendencyProgressBarService, TrendencyFullscreenLoaderService],
  exports: [
    TrendencyProgressBarComponent,
    TrendFullscreenLoaderComponent,
    TrendencyButtonLoadDirective,
    TrendencyIframeLazyLoadDirective,
  ],
})
export class TrendencyLoadingModule {
  private navigationProgressBarInitied: boolean;

  constructor(
    private readonly router: Router,
    private readonly progressBarService: TrendencyProgressBarService,
    private readonly appRef: ApplicationRef,
    private readonly utilsService: TrendencyUtilsService
  ) {
    this.setNavigationProgressBar();
  }

  private getCurrentNavigationState(): Record<string, unknown> {
    return this.router.getCurrentNavigation()?.extras?.state ?? {};
  }

  private setNavigationProgressBar(): void {
    if (this.utilsService.isBrowser() && !this.navigationProgressBarInitied) {
      const body = document.getElementsByTagName('body')[0];
      if (body) {
        this.utilsService.createDynamicComponent(TrendencyProgressBarComponent, this.appRef, body);
        this.navigationProgressBarInitied = true;
      }

      this.router.events.subscribe(event => {
        const state = this.getCurrentNavigationState();
        if (state && state.ignoreLoadingBar) {
          return;
        }

        if (event instanceof NavigationStart) {
          this.progressBarService.start();
        }

        if (
          event instanceof NavigationError ||
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof ActivationEnd
        ) {
          this.progressBarService.complete();
        }
      });
    }
  }
}
