import { Injectable } from '@angular/core';
import {
  ConsentBlockSetting,
  TrackingConfig,
  TrackingScript,
} from '@trendency/consent';
import { environment } from 'src/environments/environment';

const GA4_SCRIPT_ID = 'ga4-script';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService implements TrackingScript {
  consentTable: Record<string, ConsentBlockSetting> = {
    en: {
      title: 'Google analytics',
      description: `Google Analytics cookie policy can be read at the following <a
      href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
      target="_blank" class="cc-link">link</a>`,
      cookie_table: [
        // list of all expected cookies
        {
          col1: `1P_JAR`,
          col2: 'Google',
          col3: '1 month',
          col4: `It collects data for site statistics and allows to submit targeted ads.`,
        },
        {
          col1: `NID`,
          col2: 'Google',
          col3: '6 months',
          col4: `Settings are used to store a unique Google ID to remember user information such as preferred language, search results per page, and SafeSearch filter preferences.`,
        },
        {
          col1: `ANID`,
          col2: 'Google',
          col3: '390 days',
          col4: `It is used to link the user's activities on different devices to display more relevant ads.`,
        },
        {
          col1: `AWSALBCORS`,
          col2: 'starling.crowdrriff.com',
          col3: '6 days',
          col4: `It stores which server center is serving the user, used in connection with load balancing.`,
        },
        {
          col1: `CONSENT`,
          col2: 'Google',
          col3: '20 years',
          col4: `Stores consent to the handling of cookies.`,
        }
      ],
    },

  };
  config: TrackingConfig = {
    value: 'ga',
    enabled: true,
    readonly: false,
  };
  loadScript(_isFirstAction: boolean): void {
    if (document.getElementById(GA4_SCRIPT_ID)) {
      return;
    }

    const script = document.createElement('script');
    script.id = GA4_SCRIPT_ID;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsCode}`;
    script.onload = (): void => {
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', '${environment.googleAnalyticsCode}', {'send_page_view': true});
          `;
      document.head.appendChild(gaScript);
    };

    document.head.appendChild(script);


  }

  removeScript(): void {
    const scriptElem = document.getElementById(GA4_SCRIPT_ID);
    if (!scriptElem) {
      return;
    }

    scriptElem.remove();
  }

}